<script>
/* eslint-disable */
import PageHeader from '@/components/page-header';
import appConfig from '@/app.config';
import Layout from '../../layouts/main';

/**
 * Product-cart component
 */
export default {
  page: {
    title: 'Cart',
    meta: [{name: 'description', content: appConfig.description}],
  },
  components: {Layout, PageHeader},
  data() {
    return {
      title: 'Cart',
      items: [
        {
          text: 'Ecommerce',
          href: '/',
        },
        {
          text: 'Cart',
          active: true,
        },
      ],
    };
  },
};
</script>

<template>
  <Layout>
    <PageHeader :items="items" :title="title"/>
    <div class="row">
      <div class="col-xl-8">
        <div class="card">
          <div class="card-body">
            <div class="table-responsive">
              <table class="table align-middle mb-0 table-nowrap">
                <thead class="table-light">
                <tr>
                  <th>Product</th>
                  <th>Product Desc</th>
                  <th>Price</th>
                  <th>Quantity</th>
                  <th colspan="2">Total</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td>
                    <img
                      alt="product-img"
                      class="avatar-md"
                      src="@/assets/images/product/img-1.png"
                      title="product-img"
                    />
                  </td>
                  <td>
                    <h5 class="font-size-14 text-truncate">
                      <router-link
                        class="text-dark"
                        to="/ecommerce/product-detail"
                      >Half sleeve T-shirt
                      </router-link
                      >
                    </h5>
                    <p class="mb-0">
                      Color :
                      <span class="fw-medium">Maroon</span>
                    </p>
                  </td>
                  <td>$ 450</td>
                  <td>
                    <input
                      class="form-control"
                      name="demo_vertical"
                      style="width: 120px"
                      type="number"
                      value="2"
                    />
                  </td>
                  <td>$ 900</td>
                  <td>
                    <a
                      class="action-icon text-danger"
                      href="javascript:void(0);"
                    >
                      <i class="mdi mdi-trash-can font-size-18"></i>
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>
                    <img
                      alt="product-img"
                      class="avatar-md"
                      src="@/assets/images/product/img-2.png"
                      title="product-img"
                    />
                  </td>
                  <td>
                    <h5 class="font-size-14 text-truncate">
                      <router-link
                        class="text-dark"
                        to="/ecommerce/product-detail"
                      >Light blue T-shirt
                      </router-link
                      >
                    </h5>
                    <p class="mb-0">
                      Color :
                      <span class="fw-medium">Light blue</span>
                    </p>
                  </td>
                  <td>$ 225</td>
                  <td>
                    <input
                      class="form-control"
                      name="demo_vertical"
                      style="width: 120px"
                      type="number"
                      value="1"
                    />
                  </td>

                  <td>$ 225</td>
                  <td>
                    <a
                      class="action-icon text-danger"
                      href="javascript:void(0);"
                    >
                      <i class="mdi mdi-trash-can font-size-18"></i>
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>
                    <img
                      alt="product-img"
                      class="avatar-md"
                      src="@/assets/images/product/img-3.png"
                      title="product-img"
                    />
                  </td>
                  <td>
                    <h5 class="font-size-14 text-truncate">
                      <router-link
                        class="text-dark"
                        to="/ecommerce/product-detail"
                      >Black Color T-shirt
                      </router-link
                      >
                    </h5>
                    <p class="mb-0">
                      Color :
                      <span class="fw-medium">Black</span>
                    </p>
                  </td>
                  <td>$ 152</td>
                  <td>
                    <input
                      class="form-control"
                      name="demo_vertical"
                      style="width: 120px"
                      type="number"
                      value="2"
                    />
                  </td>

                  <td>$ 304</td>
                  <td>
                    <a
                      class="action-icon text-danger"
                      href="javascript:void(0);"
                    >
                      <i class="mdi mdi-trash-can font-size-18"></i>
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>
                    <img
                      alt="product-img"
                      class="avatar-md"
                      src="@/assets/images/product/img-4.png"
                      title="product-img"
                    />
                  </td>
                  <td>
                    <h5 class="font-size-14 text-truncate">
                      <router-link
                        class="text-dark"
                        to="/ecommerce/product-detail"
                      >Hoodie (Blue)
                      </router-link
                      >
                    </h5>
                    <p class="mb-0">
                      Color :
                      <span class="fw-medium">Blue</span>
                    </p>
                  </td>
                  <td>$ 145</td>
                  <td>
                    <input
                      class="form-control"
                      name="demo_vertical"
                      style="width: 120px"
                      type="number"
                      value="2"
                    />
                  </td>

                  <td>$ 290</td>
                  <td>
                    <a
                      class="action-icon text-danger"
                      href="javascript:void(0);"
                    >
                      <i class="mdi mdi-trash-can font-size-18"></i>
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>
                    <img
                      alt="product-img"
                      class="avatar-md"
                      src="@/assets/images/product/img-5.png"
                      title="product-img"
                    />
                  </td>
                  <td>
                    <h5 class="font-size-14 text-truncate">
                      <router-link
                        class="text-dark"
                        to="/ecommerce/product-detail"
                      >Half sleeve T-Shirt
                      </router-link
                      >
                    </h5>
                    <p class="mb-0">
                      Color :
                      <span class="fw-medium">Light orange</span>
                    </p>
                  </td>
                  <td>$ 138</td>
                  <td>
                    <input
                      class="form-control"
                      name="demo_vertical"
                      style="width: 120px"
                      type="number"
                      value="1"
                    />
                  </td>

                  <td>$ 138</td>
                  <td>
                    <a
                      class="action-icon text-danger"
                      href="javascript:void(0);"
                    >
                      <i class="mdi mdi-trash-can font-size-18"></i>
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>
                    <img
                      alt="product-img"
                      class="avatar-md"
                      src="@/assets/images/product/img-6.png"
                      title="product-img"
                    />
                  </td>
                  <td>
                    <h5 class="font-size-14 text-truncate">
                      <router-link
                        class="text-dark"
                        to="/ecommerce/product-detail"
                      >Green color T-shirt
                      </router-link
                      >
                    </h5>
                    <p class="mb-0">
                      Color :
                      <span class="fw-medium">Green</span>
                    </p>
                  </td>
                  <td>$ 152</td>
                  <td>
                    <input
                      class="form-control"
                      name="demo_vertical"
                      style="width: 120px"
                      type="number"
                      value="2"
                    />
                  </td>

                  <td>$ 304</td>
                  <td>
                    <a
                      class="action-icon text-danger"
                      href="javascript:void(0);"
                    >
                      <i class="mdi mdi-trash-can font-size-18"></i>
                    </a>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <div class="row mt-4">
              <div class="col-sm-6">
                <router-link
                  class="btn btn-secondary"
                  to="/ecommerce/product-detail/1"
                >
                  <i class="mdi mdi-arrow-left me-1"></i> Continue Shopping
                </router-link>
              </div>
              <!-- end col -->
              <div class="col-sm-6">
                <div class="text-sm-end mt-2 mt-sm-0">
                  <router-link class="btn btn-success" to="/ecommerce/checkout">
                    <i class="mdi mdi-cart-arrow-right me-1"></i> Checkout
                  </router-link>
                </div>
              </div>
              <!-- end col -->
            </div>
            <!-- end row-->
          </div>
        </div>
      </div>
      <div class="col-xl-4">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title mb-4">Card Details</h5>

            <div class="card bg-primary text-white visa-card mb-0">
              <div class="card-body">
                <div>
                  <i class="bx bxl-visa visa-pattern"></i>

                  <div class="float-end">
                    <i class="bx bxl-visa visa-logo display-3"></i>
                  </div>

                  <div>
                    <i class="bx bx-chip h1 text-warning"></i>
                  </div>
                </div>

                <div class="row mt-5">
                  <div class="col-4">
                    <p>
                      <i class="fas fa-star-of-life m-1"></i>
                      <i class="fas fa-star-of-life m-1"></i>
                      <i class="fas fa-star-of-life m-1"></i>
                    </p>
                  </div>
                  <div class="col-4">
                    <p>
                      <i class="fas fa-star-of-life m-1"></i>
                      <i class="fas fa-star-of-life m-1"></i>
                      <i class="fas fa-star-of-life m-1"></i>
                    </p>
                  </div>
                  <div class="col-4">
                    <p>
                      <i class="fas fa-star-of-life m-1"></i>
                      <i class="fas fa-star-of-life m-1"></i>
                      <i class="fas fa-star-of-life m-1"></i>
                    </p>
                  </div>
                </div>

                <div class="mt-5">
                  <h5 class="text-white float-end mb-0">12/22</h5>
                  <h5 class="text-white mb-0">Fredrick Taylor</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-3">Order Summary</h4>

            <div class="table-responsive">
              <table class="table mb-0">
                <tbody>
                <tr>
                  <td>Grand Total :</td>
                  <td>$ 1,857</td>
                </tr>
                <tr>
                  <td>Discount :</td>
                  <td>- $ 157</td>
                </tr>
                <tr>
                  <td>Shipping Charge :</td>
                  <td>$ 25</td>
                </tr>
                <tr>
                  <td>Estimated Tax :</td>
                  <td>$ 19.22</td>
                </tr>
                <tr>
                  <th>Total :</th>
                  <th>$ 1744.22</th>
                </tr>
                </tbody>
              </table>
            </div>
            <!-- end table-responsive -->
          </div>
        </div>
        <!-- end card -->
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>
